<template>
  <section>
    <div class="panel-contents">
      <!---------------------------- 카테고리 관리 ---------------------------->
      <div class="col-337">
        <div class="panel">
          <h4 class="panel-top-title">카테고리 관리</h4>
          <div class="panel-body" style="height:710px">
            <div class="ctgryPanel">
              <!-- overlay -->
              <v-overlay absolute="absolute" :value="overlay">
                <v-progress-circular
                  indeterminate
                  size="50"
                  color="deep-orange lighten-2"
                ></v-progress-circular>
              </v-overlay>
              <!--- 컨텐츠 유형 --->
              <div>
                <div class="upper-ctgry">
                  <span data-type="goodsCnTypeCode">컨텐츠 유형</span>
                </div>
                <ul>
                  <li v-for="(item, index) in goodsCnTypeCode" :key="index">
                    <span>{{ item.codeName }}</span>
                  </li>
                </ul>
              </div>
              <!-- 카테고리 목록 -->
              <section :key="reRenderFlag">
                <div class="goodsCtgry-div">
                  <section v-for="(item, index) in ctgryList" :key="index">
                    <!-- 상위 -->
                    <div class="upper-ctgry">
                      <span>{{ item.ctgryNm }}</span>
                      <input
                        v-show="editMode"
                        type="button"
                        class="bt-num-minus-sm"
                        style="right:30px;"
                        @click="
                          chkCtgry(
                            {
                              index: null,
                              goodsCtgryCode: item.upperGoodsCtgryCode,
                            },
                            {
                              index: index,
                              goodsCtgryCode: item.goodsCtgryCode,
                            },
                          )
                        "
                      />
                      <input
                        v-show="editMode"
                        type="button"
                        class="bt-num-plus-sm"
                        @click="addSubCtgry({ index, ...item })"
                      />
                    </div>
                    <!-- 하위 -->
                    <ul>
                      <!-- 기존 하위카테고리 -->
                      <li
                        v-for="(subItem, subIndex) in item.subGoodsCtgryCode"
                        :key="subIndex"
                      >
                        <span>{{ subItem.ctgryNm }}</span>
                        <input
                          v-show="editMode"
                          type="button"
                          class="bt-num-minus-sm"
                          @click="
                            chkCtgry(
                              { ...item, index: index },
                              { ...subItem, index: subIndex },
                            )
                          "
                        />
                      </li>
                      <!-- 신규 하위카테고리 -->
                      <template>
                        <li
                          v-for="(newItem, newIndex) in submitCtgry
                            .addCtgryList[item.goodsCtgryCode]"
                          :key="`new-${newIndex}`"
                        >
                          <input
                            type="text"
                            class="input-24"
                            v-model="newItem.ctgryNm"
                          />
                          <input
                            type="button"
                            class="bt-num-minus-sm"
                            @click="
                              () => {
                                // 카테고리 삭제
                                submitCtgry.addCtgryList[
                                  item.goodsCtgryCode
                                ].splice(newIndex, 1);
                                // 배열 확인 후 0개인 경우, object 삭제
                                if (
                                  submitCtgry.addCtgryList[item.goodsCtgryCode]
                                    .length == 0
                                ) {
                                  delete submitCtgry.addCtgryList[
                                    item.goodsCtgryCode
                                  ];
                                }
                                reRenderFlag = !reRenderFlag;
                              }
                            "
                          />
                        </li>
                      </template>
                    </ul>
                  </section>
                </div>
                <!-- 신규 상위/하위 카테고리 -->
                <div class="newGoodsCtgry-div">
                  <section
                    v-for="(item, index) in submitCtgry.newCtgryList"
                    :key="index"
                  >
                    <!-- 상위 -->
                    <div class="upper-ctgry">
                      <input
                        type="text"
                        class="input-24"
                        v-model="submitCtgry.newCtgryList[index].ctgryNm"
                        style="margin-left: 20px; background: white; font-weight: bold;"
                      />
                      <input
                        v-show="editMode"
                        type="button"
                        class="bt-num-minus-sm"
                        style="right:30px;"
                        @click="
                          () => {
                            if (item.subGoodsCtgryCode.length) {
                              $dialogs.alert(
                                '하위 카테고리를 먼저 삭제해주세요.',
                              );
                              return;
                            }
                            submitCtgry.newCtgryList.splice(index, 1);
                          }
                        "
                      />
                      <input
                        v-show="editMode"
                        type="button"
                        class="bt-num-plus-sm"
                        @click="addNewCtgry(index)"
                      />
                    </div>
                    <!-- 하위 -->
                    <ul>
                      <li
                        v-for="(subItem, subIndex) in item.subGoodsCtgryCode"
                        :key="`new-${subIndex}`"
                      >
                        <input
                          type="text"
                          class="input-24"
                          v-model="
                            submitCtgry.newCtgryList[index].subGoodsCtgryCode[
                              subIndex
                            ].ctgryNm
                          "
                        />
                        <input
                          type="button"
                          class="bt-num-minus-sm"
                          @click="
                            () => {
                              submitCtgry.newCtgryList[
                                index
                              ].subGoodsCtgryCode.splice(subIndex, 1);
                              reRenderFlag = !reRenderFlag;
                            }
                          "
                        />
                      </li>
                    </ul>
                  </section>
                </div>
              </section>
            </div>
            <div class="table-bot-btns" style="border-top: 1px solid #ddd;">
              <p class="f-right">
                <input
                  type="button"
                  v-show="!editMode"
                  value="편집"
                  class="bt-line24 cr-orange"
                  @click="editMode = !editMode"
                />
                <input
                  type="button"
                  v-show="editMode"
                  value="카테고리 추가"
                  class="bt-line24 cr-gray"
                  @click="addNewCtgry()"
                />
                <input
                  type="button"
                  v-show="editMode"
                  value="취소"
                  class="bt-line24 cr-gray ml-1"
                  @click="reloadCtgry"
                />
                <input
                  type="button"
                  v-show="editMode"
                  value="완료"
                  class="bt-line24 cr-orange ml-1"
                  @click="updateSubmit"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <!---------------------------- 컨텐츠 목록 ---------------------------->
      <div class="col-1050">
        <div class="panel">
          <!---- 컨텐츠 현황 ---->
          <h4 class="panel-top-title">컨텐츠 등록 현황</h4>
          <div class="goods-top-preview">
            <div class="goods-top-info">
              <p>전체 누적 수</p>
              <p>
                <span>{{ goodsStatus.goodsTotalCnt }}</span>
                <small>건</small>
              </p>
            </div>
            <div class="goods-top-info">
              <p>신규 등록</p>
              <p>
                <span>{{ goodsStatus.goodsNewCnt }}</span>
                <small>건</small>
              </p>
            </div>
            <div class="goods-top-info-detail">
              <p>카테고리 유형</p>
              <p v-for="(item, index) in goodsStatus.ctgryCnt" :key="index">
                <label>{{ item.ctgryNm }}</label>
                <span>{{ item.goodsCnt }}</span>
                <small>건</small>
              </p>
            </div>
            <div class="goods-top-info-detail" id="optionStatus">
              <p>
                <select
                  class="sort-input-select"
                  style="height: 25px; font-size: 14px;"
                  v-model="statusSearch.upperGoodsCtgryCode"
                  @change="searchGoodsCtgryCnt"
                >
                  <option
                    v-for="(item, index) in upperGoodsCtgryCode"
                    :value="item.goodsCtgryCode"
                    :key="index"
                  >
                    {{ item.ctgryNm }}
                  </option>
                </select>
                컨텐츠
              </p>
              <p v-if="!statusSearch.items.length" class="optionCtgryCnt">
                하위 카테고리가 없습니다.
              </p>
              <p
                v-else
                v-for="(item, index) in statusSearch.items"
                :key="index"
                class="optionCtgryCnt"
              >
                <label>{{ item.ctgryNm }}</label>
                <span>{{ item.goodsCnt }}</span
                ><small>건</small>
              </p>
            </div>
          </div>
          <!---- 컨텐츠 목록---->
          <table-list-component
            ref="tableListComponent"
            :tableHeight="'440'"
            :headers="headers"
            :items="customItems"
            :nowPerPage="nowPerPage"
            :totalRecords="totalRecords"
            :totalPages="totalPages"
            :searchOpts="searchOpts"
            :loading="loading"
            :reloadToggle="reloadToggle"
            :customSlotInfo="customSlotInfo"
            @getDataFromApi="searchGoodsList"
          >
            <!---- 검색 옵션 ---->
            <template #searchOptions>
              <label class="sort-label">· 컨텐츠 유형</label>
              <select
                v-model="searchOpts.goodsCnTypeCode"
                class="sort-input-select"
                style="width: 100px;"
              >
                <option value="">전체</option>
                <option
                  v-for="(item, index) in goodsCnTypeCode"
                  :key="index"
                  :value="item.codeId"
                >
                  {{ item.codeName }}
                </option>
              </select>
              <label class="searchUpperGoodsCtgryCode ml-3">
                · 상위 카테고리
              </label>
              <select
                v-model="searchOpts.upperGoodsCtgryCode"
                class="sort-input-select"
                style="width: 100px;"
                @change="getGoodsCtgryCode"
              >
                <option value="">전체</option>
                <option
                  v-for="(item, index) in upperGoodsCtgryCode"
                  :key="index"
                  :value="item.goodsCtgryCode"
                >
                  {{ item.ctgryNm }}
                </option>
              </select>
              <label class="searchGoodsCtgryCode ml-3">· 하위 카테고리</label>
              <select
                v-model="searchOpts.goodsCtgryCode"
                class="sort-input-select ml-1"
                style="width: 100px;"
              >
                <option value="">전체</option>
                <option
                  v-for="(item, index) in goodsCtgryCode"
                  :key="index"
                  :value="item.goodsCtgryCode"
                >
                  {{ item.ctgryNm }}
                </option>
              </select>
              <label for="searchWord" class="sort-label  ml-3"
                >· 컨텐츠명</label
              >
              <input
                v-model="searchOpts.searchWord"
                type="text"
                class="sort-input-text"
                @keyup.enter="keyUpEnter"
              />
            </template>
            <!---- customSlot ---->
            <template v-slot:goodsSttusNm_custom="{ item }">
              <td
                :class="
                  item.goodsSttusNm == '판매중'
                    ? 'text-center c-blue'
                    : 'text-center'
                "
              >
                {{ item.goodsSttusNm }}
              </td>
            </template>
            <template v-slot:detailBtn_custom="{ item }">
              <td class="text-center">
                <input
                  type="button"
                  value="보기"
                  class="bt-line22"
                  @click="openGoodsDetail(item.goodsSn)"
                />
              </td>
            </template>
          </table-list-component>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TableListComponent from '@/components/TableListComponent.vue';
import {
  getCtgryCnt,
  getGoodsList,
  getGoodsCtgryCode,
  chkCtgry,
  updateCtgry,
} from '@/api/marketApi';

export default {
  components: {
    TableListComponent,
  },
  data() {
    return {
      // --- 카테고리 관리 ---//
      overlay: false,
      editMode: false, // 편집 모드
      reRenderFlag: false, // v-for문 rerender
      ctgryList: this.$store.state.market.ctgryList,
      submitCtgry: {
        addCtgryList: {}, // key: upperGoodsCtgryCode, value { upperGoodsCtgryCode, ctgryNm }
        newCtgryList: [], // { ctgryNm, subGoodsCtgryCode[{ctgryNm}] }
        deleteCtgryList: [], // {upperGoodsCtgryCode, goodsCtgryCode}
      },
      // --- 컨텐츠 현황 --- //
      statusSearch: {
        upperGoodsCtgryCode: '',
        items: [],
      },
      // --- search select --- //
      goodsCnTypeCode: this.$store.state.common.goodsCnTypeCode,
      goodsCtgryCode: [],
      // --- 테이블 --- //
      loading: false,
      headers: [
        { text: '컨텐츠명', value: 'goodsNm', sortable: false, width: '22%' },
        { text: '유형', value: 'goodsCnTypeNm', sortable: false, width: '10%' },
        { text: '회사명', value: 'cmpnyNm', sortable: false, width: '10%' },
        { text: '상태', value: 'goodsSttusNm', width: '10%' },
        { text: '카테고리', value: 'ctgry', sortable: false, width: '17%' },
        { text: '구매 수', value: 'purchsCo', width: '8%' },
        { text: '등록일', value: 'creatDt', width: '15%' },
        { text: '상세', value: 'detailBtn', sortable: false, width: '8%' },
      ],
      // --- custom Slot --- //
      customSlotInfo: [
        { name: 'goodsSttusNm', slotName: 'item.goodsSttusNm' },
        { name: 'detailBtn', slotName: 'item.detailBtn' },
      ],
      // 검색 정보
      searchOpts: {
        goodsCnTypeCode: '',
        upperGoodsCtgryCode: '',
        goodsCtgryCode: '',
        searchWord: '',
      },
      // row
      totalRecords: 0,
      totalPages: 0,
      tableItems: [],
      nowPerPage: 30,
      reloadToggle: true,
    };
  },
  computed: {
    // --- 컨텐츠 현황 --- //
    goodsStatus() {
      return this.$store.state.market.goodsStatus;
    },
    // --- search select --- //
    upperGoodsCtgryCode() {
      return this.$store.state.market.upperGoodsCtgryCode;
    },
    // --- tableItem --- //
    customItems() {
      return this.tableItems.map(tableItem => ({
        ...tableItem,
        ctgry: `${tableItem.upperGoodsCtgryNm} > ${tableItem.goodsCtgryNm}`,
      }));
    },
    // -- 카테고리 update valiate -- //
    updateValidate() {
      let validate = true;

      // 업데이트 후, 상위카테고리 재조회/컨텐츠목록 재조회/reloadToggle
      const { addCtgryList, newCtgryList } = this.submitCtgry;

      // 기존 상위 카테고리의 하위 카테고리 명 validate
      Object.values(addCtgryList).forEach(ctgryList => {
        if (ctgryList.filter(item => item.ctgryNm == '').length > 0) {
          validate = false;
        }
      });

      // 신규 상위 카테고리 명 validate
      if (validate) {
        if (newCtgryList.filter(item => item.ctgryNm == '').length > 0) {
          validate = false;
        }
      }

      if (validate) {
        // 신규 상위 카테고리의 하위 카테고리명 validate
        newCtgryList.forEach(item => {
          if (
            item.subGoodsCtgryCode.filter(subItem => subItem.ctgryNm == '')
              .length > 0
          ) {
            validate = false;
          }
        });
      }

      return validate;
    },
  },
  methods: {
    // 컨텐츠 현황: select 개수 조회
    async searchGoodsCtgryCnt() {
      await getCtgryCnt(this.statusSearch.upperGoodsCtgryCode).then(
        ({ data }) => {
          this.statusSearch.items = data.subGoodsCtgryCode;
        },
      );
    },
    // 컨텐츠 목록: search바 상위 카테고리에 따른 하위카테고리 조회
    async getGoodsCtgryCode() {
      this.goodsCtgryCode = [];
      this.searchOpts.goodsCtgryCode = '';
      if (this.searchOpts.upperGoodsCtgryCode != '') {
        let codeList = [];
        await getGoodsCtgryCode(this.searchOpts.upperGoodsCtgryCode).then(
          ({ data }) => {
            data.forEach(({ goodsCtgryCode, ctgryNm }) => {
              codeList.push({ goodsCtgryCode, ctgryNm });
            });
          },
        );
        this.goodsCtgryCode = codeList;
      }
    },
    // 컨텐츠 목록
    async searchGoodsList(searchParams) {
      this.loading = true;
      await getGoodsList(searchParams).then(res => {
        const { rows, totalRecords, totalPages } = res.data;
        this.tableItems = rows;
        this.totalRecords = totalRecords;
        this.totalPages = totalPages;
        this.loading = false;
      });
    },
    // 컨텐츠 정보 보기
    openGoodsDetail(goodsSn) {
      var url = '';
      if (location.host.startsWith('onm')) {
        url = location.protocol + '//' + 'market.redwoodhealth.kr';
      } else if (location.host.startsWith('onmtb')) {
        url = location.protocol + '//' + 'markettb.redwoodhealth.kr';
      } else {
        url = location.protocol + '//' + 'marketaz.redwoodhealth.kr';
      }
      url += '/market/product/goods/goods-view.html?goodsSn=' + goodsSn;
      window.open(
        url,
        '상품 상페이지',
        'toolbar=no, menubar=no, scrollbars=no, resizable=yes',
      );
    },
    // 카테고리 수정
    async updateSubmit() {
      if (!this.updateValidate) {
        this.$dialogs.alert('카테고리명을 입력해주세요.');
        return;
      }

      this.$dialogs.password().then(async ({ ok }) => {
        if (ok) {
          // payload addCtgryList 담기
          this.overlay = true;
          let reqAddCtgryList = [];
          const {
            addCtgryList,
            deleteCtgryList,
            newCtgryList,
          } = this.submitCtgry;

          Object.values(addCtgryList).forEach(ctgryList => {
            reqAddCtgryList = reqAddCtgryList.concat(ctgryList);
          });

          const payload = {
            addCtgryList: reqAddCtgryList,
            deleteCtgryList,
            newCtgryList,
          };

          /**
           * 수정 후, 전체 화면 state 재조회 및 현황/목록 검색 초기화
           */
          await updateCtgry(payload).then(({ data }) => {
            if (data.valid) {
              this.$dialogs.alert('수정되었습니다.').then(async () => {
                // 카테고리 목록 재조회
                this.reloadCtgry();
                // 컨텐츠 상위 카테고리 조회
                await this.$store
                  .dispatch('market/FETCH_UPPER_GOODS_CTGRY_CODE')
                  .then(async () => {
                    // 컨텐츠 현황 조회
                    await this.$store
                      .dispatch('market/FETCH_GOODS_STATUS')
                      .then(() => {
                        // 목록 검색 초기화
                        this.searchOpts.goodsCnTypeCode = '';
                        this.searchOpts.upperGoodsCtgryCode = '';
                        this.searchOpts.goodsCtgryCode = '';
                        this.searchOpts.searchWord = '';
                        this.reloadToggle = !this.reloadToggle;
                        // 컨텐츠 등록 현황 갯수 재 조회
                        this.statusSearch.upperGoodsCtgryCode = this.upperGoodsCtgryCode[0].goodsCtgryCode;
                        this.searchGoodsCtgryCnt();
                      });
                  });
              });
            }
          });
        }
      });
    },
    // 카테고리 reload
    async reloadCtgry() {
      this.overlay = true;

      // 카테고리 목록 조회
      await this.$store.dispatch('market/FETCH_CTGRY_LIST').then(({ data }) => {
        // 카테고리 목록 초기화
        this.ctgryList = data;
        // submitCtgry 초기화
        this.submitCtgry.addCtgryList = {};
        this.submitCtgry.newCtgryList = [];
        this.submitCtgry.deleteCtgryList = [];

        this.editMode = false;
        this.overlay = false;
      });
    },
    // 기존 상위 > 하위 카테고리 추가
    addSubCtgry(item) {
      if (!this.submitCtgry.addCtgryList[item.goodsCtgryCode]) {
        this.submitCtgry.addCtgryList[item.goodsCtgryCode] = [];
      }
      this.submitCtgry.addCtgryList[item.goodsCtgryCode].push({
        ctgryNm: '',
        upperGoodsCtgryCode: item.goodsCtgryCode,
      });
      this.reRenderFlag = !this.reRenderFlag;
    },
    // 신규 카테고리 (상위/하위) 추가
    addNewCtgry(index) {
      if (index == null) {
        // index가 null일 경우, 상위 카테고리
        this.submitCtgry.newCtgryList.push({
          ctgryNm: '',
          subGoodsCtgryCode: [],
        });
      } else {
        this.submitCtgry.newCtgryList[index].subGoodsCtgryCode.push({
          ctgryNm: '',
        });
      }
    },
    // 카테고리 삭제 전, 상품 존재 여부 확인
    async chkCtgry(item, subItem) {
      const { index: upperIndex, goodsCtgryCode: upperGoodsCtgryCode } = item; // 상위 (최상위)
      const { index: index, goodsCtgryCode: goodsCtgryCode } = subItem; // 하위 (상위) - 삭제할 카테고리

      /**
       * 상위 카테고리를 삭제할 경우,, 하위 카테고리 존재여부 확인
       * 기존 하위 카테고리를 다 삭제하지 않았거나 신규 카테고리가 존재하는 경우
       */
      if (upperIndex == null) {
        if (
          this.ctgryList[index].subGoodsCtgryCode.length ||
          this.submitCtgry.addCtgryList[goodsCtgryCode]?.length
        ) {
          this.$dialogs.alert('하위 카테고리를 먼저 삭제해주세요');
          return;
        }
      }

      // 카테고리 상품 존재하는 지 확인
      await chkCtgry({
        upperGoodsCtgryCode,
        goodsCtgryCode,
      }).then(({ data }) => {
        const { valid, message } = data;
        if (valid) {
          // data에서 remove (상위 카테고리 remove할 경우, 최상위인덱스 null)
          if (upperIndex == null) {
            this.ctgryList.splice(index, 1);
          } else {
            this.ctgryList[upperIndex].subGoodsCtgryCode.splice(index, 1);
          }

          // submitCtgry 추가
          this.submitCtgry.deleteCtgryList.push({
            goodsCtgryCode,
            upperGoodsCtgryCode,
          });
        } else {
          // 카테고리에 속한 상품 존재 시, alert
          this.$dialogs.alert(message.replaceAll('\\n', '<br/>'));
        }
      });
    },
    keyUpEnter() {
      this.$refs.tableListComponent.search();
    },
  },

  created() {
    // 컨텐츠 현황: select 갯수 조회
    this.statusSearch.upperGoodsCtgryCode = this.upperGoodsCtgryCode[0].goodsCtgryCode;
    this.searchGoodsCtgryCnt();
  },
};
</script>

<style scoped>
.col-337 {
  margin-left: unset;
}
.col-1050 {
  margin-left: 3px;
}

.upper-ctgry {
  line-height: 32px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background: #e3e7ef;
}
.upper-ctgry span {
  margin-left: 20px;
  font-size: 14px;
  font-weight: bold;
}
.ctgryPanel div > ul > li {
  line-height: 32px;
  margin-left: 40px;
  font-size: 12px;
  font-weight: bold;
}
.ctgryPanel section > div > section > ul > li {
  line-height: 32px;
  margin-left: 40px;
  font-size: 12px;
  font-weight: bold;
}
.bt-num-minus-sm,
.bt-num-plus-sm {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 0;
  font-size: 0;
  position: absolute;
  right: 6px;
  margin-top: 2%;
}
.bt-num-plus-sm {
  background: url('../../assets/images/img_popup/input_plus.png');
  background-size: 20px;
}
.bt-num-minus-sm {
  background: url('../../assets/images/img_popup/input_minus.png');
  background-size: 20px;
}
.bt-num-plus-sm:hover {
  background: url('../../assets/images/img_popup/input_plus_over.png');
  background-size: 20px;
}
.bt-num-minus-sm:hover {
  background: url('../../assets/images/img_popup/input_minus_over.png');
  background-size: 20px;
}

.goods-top-preview {
  height: 163px;
  border-top: 1px solid #ddd;
  display: flex;
  padding: 0px 10px;
}
.goods-top-preview div {
  padding: 0px 20px;
  margin: 20px 0px;
}
.goods-top-preview div:nth-child(1) {
  width: 25%;
  border-right: 1px solid #ddd;
}
.goods-top-preview div:nth-child(2) {
  width: 25%;
  border-right: 1px solid #ddd;
}
.goods-top-preview div:nth-child(3) {
  width: 25%;
}
.goods-top-preview div:nth-child(4) {
  width: 25%;
}

.goods-top-info {
  text-align: center;
}

.goods-top-info p:nth-child(1) {
  line-height: 50px;
  font-size: 16px;
  font-weight: bold;
}

.goods-top-info p:nth-child(2) {
  font-size: 40px;
  font-weight: bold;
}
.goods-top-info p:nth-child(2) > small {
  font-size: 25px;
  margin-left: 4px;
  opacity: 0.4;
}

.goods-top-info-detail {
  overflow-y: auto;
}

.goods-top-info-detail p:nth-child(1) {
  line-height: 40px;
  font-size: 15px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  margin-bottom: 5px;
  text-align: left;
  width: 100%;
}
.goods-top-info-detail p > input {
  float: right;
  margin-top: 10px;
}
.goods-top-info-detail p {
  font-size: 13px;
  line-height: 24px;
  text-align: right;
}
.goods-top-info-detail p > label {
  font-weight: 800;
  float: left;
}
.goods-top-info-detail p > span {
  font-weight: 800;
}
.goods-top-info-detail p > span > small {
  margin-left: 4px;
  opacity: 0.6;
}
</style>
