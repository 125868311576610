<template>
  <section>
    <div class="panel-contents">
      <div class="col-default">
        <div class="panel" id="listPanel">
          <h4 class="panel-top-title">월별 입점 승인 현황</h4>
          <div class="approve-top-preview">
            <div class="approve-status-div">
              <div class="approve-status-item">
                <button
                  :class="{ active: isHoveringL, passive: !isHoveringL }"
                  @mouseover="isHoveringL = true"
                  @mouseleave="isHoveringL = false"
                  @click="changeMonth('pre')"
                >
                  <li class="pg-prev"></li>
                </button>
              </div>
              <div class="approve-status-item">
                <span style="font-size:16px; font-weight:bold; color:#ddd"
                  >{{ approveStatus.year }}년</span
                ><br />
                <span style="font-size:40px; font-weight:bold;"
                  >{{ approveStatus.mon }}월</span
                >
              </div>
              <div class="approve-status-item">
                <button
                  :class="{ active: isHoveringR, passive: !isHoveringR }"
                  @mouseover="isHoveringR = true"
                  @mouseleave="isHoveringR = false"
                  @click="changeMonth('next')"
                >
                  <li class="pg-next"></li>
                </button>
              </div>
            </div>
            <div class="approve-top-info">
              <p>신규</p>
              <p>
                <span>{{ approveStatus.unApprovedStoreCnt }}</span
                ><small>건</small>
              </p>
            </div>
            <div class="approve-top-info">
              <p>보류</p>
              <p>
                <span>{{ approveStatus.rejectedStoreCnt }}</span
                ><small>건</small>
              </p>
            </div>
            <div class="approve-top-info">
              <p>승인</p>
              <p>
                <span>{{ approveStatus.approvedStoreCnt }}</span
                ><small>건</small>
              </p>
            </div>
            <div class="approve-top-info">
              <p>입점 신청 처리 건수</p>
              <p>
                <span>{{ approveStatus.approveProcessCnt }}</span
                ><small>건</small>
              </p>
            </div>
          </div>
          <h4 class="panel-top-title">판매자 입점 목록</h4>
          <table-list-component
            ref="tableListComponent"
            :tableHeight="'421'"
            :headers="headers"
            :items="tableItems"
            :nowPerPage="nowPerPage"
            :totalRecords="totalRecords"
            :totalPages="totalPages"
            :searchOpts="searchOpts"
            :loading="loading"
            :customSlotInfo="customSlotInfo"
            @getDataFromApi="approveSrchList"
          >
            <!---- 검색 옵션 ---->
            <template #searchOptions>
              <label class="sort-label">· 검색옵션</label>
              <select
                class="sort-input-select"
                style="width: 100px;"
                v-model="searchOpts.searchType"
              >
                <option :value="'all'">전체</option>
                <option :value="'cmpnyNm'">회사명</option>
                <option :value="'oprtrId'">아이디</option>
                <option :value="'oprtrNm'">담당자명</option>
              </select>
              <label for="searchWord" class="sort-label">· 검색어</label>
              <input
                type="text"
                class="sort-input-text"
                v-model="searchOpts.searchWord"
                @keyup.enter="keyUpEnter"
              />
              <label class="sort-label">· 등록일시</label>
              <span class="daterange-wrap">
                <vc-date-picker
                  v-model="searchOpts.dateRange"
                  mode="date"
                  :columns="$screens({ default: 1, lg: 2 })"
                  is-range
                  color="orange"
                >
                  <template v-slot="{ inputValue, togglePopover }">
                    <input
                      type="text"
                      class="sort-input-text"
                      style="width: 170px;"
                      dataType="LITERAL"
                      id="findDateDaterangepicker"
                      :value="
                        inputValue.start != null
                          ? `${inputValue.start} ~ ${inputValue.end}`
                          : null
                      "
                      autocomplete="off"
                      @click="togglePopover()"
                      @keyup.enter="keyUpEnter"
                    />
                    <label
                      for="findDateDaterangepicker"
                      class="ic-calendar"
                    ></label>
                  </template>
                </vc-date-picker>
              </span>
            </template>
            <!---- customSlot ---->
            <template v-slot:confmSttusCode_custom="{ item }">
              <td
                class="text-center"
                :style="{ color: getSttusColor(item.confmSttusCode) }"
              >
                {{ getSttusNm(item.confmSttusCode) }}
              </td>
            </template>
            <template v-slot:goodsIntrcnFileNm_custom="{ item }">
              <td
                class="text-center td-ellipsis"
                :title="item.goodsIntrcnFileNm"
              >
                {{ item.goodsIntrcnFileNm || '-' }}
              </td>
            </template>
            <template v-slot:bsnmCeregrtFileNm_custom="{ item }">
              <td
                class="text-center td-ellipsis"
                :title="item.bsnmCeregrtFileNm"
              >
                {{ item.bsnmCeregrtFileNm || '-' }}
              </td>
            </template>
            <template v-slot:confmSttusCodeBtn_custom="{ item }">
              <td class="text-center">
                <input
                  type="button"
                  :value="'보기'"
                  class="bt-line22"
                  @click="openStoreDetail(item.oprtrId)"
                />
              </td>
            </template>
          </table-list-component>
        </div>
      </div>
    </div>

    <!---------------------------- 업체 정보 팝업 ---------------------------->
    <div v-show="popup.show" title="업체 정보">
      <div class="popup-mask"></div>
      <div class="pop-content pop-ani pop-drag" style="width:500px">
        <div class="pop-head">
          <h2>업체 정보</h2>
          <button class="btn-x" @click="closePopup('all')"></button>
        </div>
        <div class="pop-body">
          <table class="table-style-default table-pop">
            <colgroup>
              <col width="106px" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr v-for="(item, key) in popup.selectedItem" :key="key">
                <th>{{ item.name }}</th>
                <td>
                  <span v-if="key == 'confmSttusCode'"
                    >{{ getSttusNm(item.value) }}
                  </span>
                  <a
                    v-else-if="
                      key == 'bsnmCeregrtFileNm' || key == 'goodsIntrcnFileNm'
                    "
                    class="a_file"
                    @click="fileDownload(key)"
                  >
                    {{ item.value || '-' }}
                  </a>
                  <span v-else>
                    {{ item.value || '-' }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="pop-btn-group">
            <input
              type="button"
              value="보류"
              class="bt-line30"
              @click="openPopupAprvRjct('R')"
            />
            <input
              type="button"
              value="승인"
              class="bt-line30 cr-orange"
              @click="openPopupAprvRjct('A')"
            />
          </p>
        </div>
      </div>
    </div>

    <!---------------------------- 입점 승인 결과 전송 (승인 사유 팝업) ---------------------------->
    <div v-show="approvePopup.show" title="입점 승인 결과 전송">
      <div class="popup-mask"></div>
      <div class="pop-content pop-ani pop-drag" style="width:350px">
        <div class="pop-head">
          <h2>입점 승인 결과 전송</h2>
          <button class="btn-x" @click="closePopup('approve')"></button>
        </div>
        <div class="pop-body">
          <table class="table-style-default table-pop">
            <colgroup>
              <col width="106px" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <td
                  colspan="2"
                  class="text-center"
                  style="border-left: 1px solid #ddd"
                >
                  <span style="font-weight: bold">{{
                    popup.selectedItem.cmpnyNm.value
                  }}</span>
                  입점 승인 완료 메세지를 <br />아래 이메일 주소로 정보를
                  전송합니다. <br /><span style="font-weight: bold">{{
                    popup.selectedItem.email.value
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="pop-btn-group">
            <input
              type="button"
              value="승인"
              class="bt-line30 cr-orange"
              @click="goUpdateApproveStatus"
            />
            <input
              type="button"
              value="취소"
              class="bt-line30"
              @click="closePopup('approve')"
            />
          </p>
        </div>
      </div>
    </div>

    <!---------------------------- 입점 승인 보류 (보류 사유 팝업) ---------------------------->
    <div v-show="rejectPopup.show" title="입점 승인 보류">
      <div class="popup-mask"></div>
      <div class="pop-content pop-ani pop-drag" style="width:350px">
        <div class="pop-head">
          <h2>입점 승인 보류</h2>
          <button class="btn-x" @click="closePopup('reject')"></button>
        </div>
        <div class="pop-body">
          <table class="table-style-default table-pop">
            <colgroup>
              <col width="106px" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <th>입점보류사유</th>
                <td>
                  <textarea
                    class="input-24 w-100 board-readonly"
                    rows="5"
                    maxlength="1000"
                    style="height:150px; overflow-y:auto;"
                    v-model="rejectPopup.returnReasonCn.value"
                  ></textarea>
                </td>
              </tr>
              <tr>
                <td
                  colspan="2"
                  class="text-center"
                  style="border-left: 1px solid #ddd"
                >
                  <span style="font-weight: bold">{{
                    popup.selectedItem.email.value
                  }}</span>
                  <br />위 이메일 주소로 입점 승인 보류 사유 <br />정보를
                  전송합니다.
                </td>
              </tr>
            </tbody>
          </table>
          <p class="pop-btn-group">
            <input
              type="button"
              value="보류"
              class="bt-line30 cr-orange"
              @click="goUpdateRejectStatus"
            />
            <input
              type="button"
              value="취소"
              class="bt-line30"
              @click="closePopup('reject')"
            />
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TableListComponent from '@/components/TableListComponent.vue';
import {
  getStoreList,
  getMonthApproveStatus,
  getStoreDetail,
  processApproveStatus,
} from '@/api/marketApi';
export default {
  components: {
    TableListComponent,
  },
  data() {
    return {
      // --- 월별 입점 승인 현황 --- //
      approveStatus: this.$store.state.market.approveStatus,

      // --- 판매자 입점 목록 --- //
      loading: false,
      headers: [
        { text: '상태', value: 'confmSttusCode', width: '7%', sortable: false },
        { text: '회사명', value: 'cmpnyNm', width: '16%' },
        {
          text: '상품소개자료',
          value: 'goodsIntrcnFileNm',
          width: '20%',
          iscustom: true,
        },
        {
          text: '사업자등록증',
          value: 'bsnmCeregrtFileNm',
          width: '20%',
          iscustom: true,
        },
        { text: '담당자', value: 'oprtrNm', width: '10%' },
        { text: '신청일자', value: 'selerRegistDt', width: '10%' },
        { text: '승인일자', value: 'confmDt', width: '10%' },
        {
          text: '승인처리',
          value: 'confmSttusCodeBtn',
          width: '7%',
          sortable: false,
        },
      ],

      // --- custom Slot --- //
      customSlotInfo: [
        { name: 'confmSttusCode', slotName: 'item.confmSttusCode' },
        { name: 'confmSttusCodeBtn', slotName: 'item.confmSttusCodeBtn' },
        { name: 'goodsIntrcnFileNm', slotName: 'item.goodsIntrcnFileNm' },
        { name: 'bsnmCeregrtFileNm', slotName: 'item.bsnmCeregrtFileNm' },
      ],
      // 검색 정보
      searchOpts: {
        searchType: 'all',
        searchWord: '',
        dateRange: '',
        pageNo: -1,
        statusPageNo: '0',
      },
      // row
      totalRecords: 0,
      totalPages: 0,
      tableItems: [],
      nowPerPage: 30,

      // 업체정보 팝업
      popup: {
        show: false,
        oprtrId: '',
        selectedItem: {
          confmSttusCode: {
            name: '승인상태',
            value: '',
          },
          cmpnyNm: {
            name: '회사명',
            value: '',
          },
          chargerTelno: {
            name: '회사전화',
            value: '',
          },
          oprtrNm: {
            name: '담당자명',
            value: '',
          },
          mbtlnum: {
            name: '연락처',
            value: '',
          },
          email: {
            name: '이메일',
            value: '',
          },
          cmpnyIntrcnCn: {
            name: '회사소개',
            value: '',
          },
          bsnmCeregrtFileNm: {
            name: '사업자등록증',
            value: '',
          },
          goodsIntrcnCn: {
            name: '상품소개',
            value: '',
          },
          goodsIntrcnFileNm: {
            name: '상품소개서',
            value: '',
          },
        },
      },

      // 승인 사유 팝업 팝업
      approvePopup: {
        show: false,
      },

      // 보류 사유 팝업 팝업
      rejectPopup: {
        show: false,
        returnReasonCn: {
          value: '',
        },
      },

      // 이전달, 다음달 마우스 오버
      isHoveringL: false,
      isHoveringR: false,
    };
  },
  methods: {
    // 월별 입점 승인 현황 (이전달, 다음달 클릭 시)
    async getStoreStatus() {
      const statusPageNo = this.searchOpts.statusPageNo;
      await getMonthApproveStatus(statusPageNo).then(({ data }) => {
        Object.keys(this.approveStatus).forEach(key => {
          this.approveStatus[key] = data[key];
        });
      });
    },

    // 판매자 입점 목록 조회
    async approveSrchList(searchParams) {
      this.loading = true;

      // 검색시에는 월별 리스트가 아닌 전체리스트를 반환한다.
      if (
        searchParams.searchWord ||
        searchParams.dateRange ||
        searchParams.searchType != 'all'
      ) {
        searchParams.statusPageNo = -1;
      } else {
        //searchParams.statusPageNo = 20;
      }

      const {
        rows,
        page,
        sord,
        sidx,
        searchType,
        searchWord,
        statusPageNo,
        dateRange,
      } = searchParams;
      const params = {
        // 리스트 표출건수
        rows,
        // 요청페이지
        page,
        // 정렬 컬럼
        sidx,
        // 정렬
        sord,
        searchType: searchType,
        searchWord: searchWord.trim(),
        pageNo: statusPageNo,
        startDateString: dateRange.start ? dateRange.start : '',
        endDateString: dateRange.end ? dateRange.end : '',
      };
      await getStoreList(params).then(res => {
        const { rows, totalRecords, totalPages } = res.data;
        this.tableItems = rows;
        this.totalRecords = totalRecords;
        this.totalPages = totalPages;
        this.loading = false;
      });
    },

    // 검색
    keyUpEnter() {
      // 검색시에는 월별 리스트가 아닌 전체리스트를 반환한다.
      this.searchOpts.statusPageNo = -1;
      this.$refs.tableListComponent.search();
    },

    // 상태 색상
    getSttusColor(val) {
      switch (val) {
        case '1':
          return 'blue';
        case '2':
          return 'grey';
        default:
          return 'red';
      }
    },

    // 상태명
    getSttusNm(val) {
      switch (Number(val)) {
        case 1:
          return '승인';
        case 2:
          return '보류';
        default:
          return '신규';
      }
    },

    // 업체 정보 팝업
    async openStoreDetail(oprtrId) {
      // 업체 정보 조회
      await getStoreDetail(oprtrId).then(({ data }) => {
        Object.keys(this.popup.selectedItem).forEach(key => {
          if (key == 'oprtrNm') {
            this.popup.selectedItem[
              key
            ].value = `${data[key]} (${data['oprtrId']})`;
          } else {
            this.popup.selectedItem[key].value = data[key];
            this.popup.oprtrId = data['oprtrId'];
          }
        });
        this.popup.show = true;
      });
    },

    // 팝업 닫기
    closePopup(type) {
      switch (type) {
        case 'all':
          this.popup.show = false;
          this.approvePopup.show = false;
          this.rejectPopup.show = false;
          Object.keys(this.popup.selectedItem).forEach(key => {
            this.popup.selectedItem[key].value = '';
          });
          this.popup.oprtrId = '';
          this.rejectPopup.returnReasonCn.value = '';
          break;
        case 'approve':
          this.approvePopup.show = false;
          break;
        case 'reject':
          this.rejectPopup.show = false;
          break;
        default:
          break;
      }
    },

    // 이전달, 다음달 조회
    changeMonth(type) {
      if (type == 'pre') {
        // 이전달
        this.searchOpts.statusPageNo++;
      } else if (type == 'next') {
        // 다음달
        this.searchOpts.statusPageNo--;
        if (this.searchOpts.statusPageNo <= 0) this.searchOpts.statusPageNo = 0;
      }

      // 월별 입점 승인 현황 갱신
      this.getStoreStatus();
      // 판매자 입점 목록 갱신
      this.initSearch();
    },

    // 검색 정보 초기화
    initSearch() {
      Object.keys(this.searchOpts).forEach(key => {
        switch (key) {
          case 'searchType':
            this.searchOpts[key] = 'all';
            break;
          case 'pageNo':
            this.searchOpts[key] = -1;
            break;
          case 'statusPageNo':
            this.searchOpts[key] = this.searchOpts.statusPageNo;
            break;
          default:
            this.searchOpts[key] = '';
            break;
        }
      });
      this.$refs.tableListComponent.search();
    },

    // 파일 다운로드 (사업자등록증, 상품소개서)
    fileDownload(type) {
      let filePath =
        type == 'bsnmCeregrtFileNm'
          ? 'bsnmCeregrtFileView'
          : 'goodsIntrcnFileView';

      const oprtrId = this.popup.oprtrId;
      window.location.href = `${process.env.VUE_APP_URL_MAS_API}/market/store/${filePath}/${oprtrId}?token=${this.$store.state.auth.token}`;
    },

    // 승인, 보류 팝업
    openPopupAprvRjct(type) {
      // 승인상태
      const sttus = this.popup.selectedItem.confmSttusCode.value;

      switch (type) {
        case 'A':
          sttus == 1
            ? this.$dialogs.alert('이미 승인 처리 되었습니다.')
            : (this.approvePopup.show = true);
          break;
        case 'R':
          sttus == 2
            ? this.$dialogs.alert('이미 보류 처리 되었습니다.')
            : (this.rejectPopup.show = true);
          break;
        default:
          break;
      }
    },

    // 승인 처리
    goUpdateApproveStatus() {
      const oprtrId = this.popup.oprtrId;

      if (!oprtrId) {
        this.$dialogs.alert('아이디가 없습니다. 목록에서 선택해 주세요.');
        return;
      }

      // 승인처리
      this.$dialogs
        .confirm(`<p>승인처리 하시겠습니까?</p>`)
        .then(async ({ ok }) => {
          if (ok) {
            try {
              const params = {
                confmSttusCode: 1,
                oprtrId: oprtrId,
                returnReasonCn: '',
              };

              await processApproveStatus(params).then(({ data }) => {
                if (data.valid) {
                  this.$dialogs.alert('처리되었습니다.').then(() => {
                    this.closePopup('all');
                    // 판매자 입점 목록 갱신
                    this.initSearch();
                  });
                } else {
                  this.$dialogs.alert('승인처리 과정에서 에러가 발생했습니다.');
                }
              });
            } catch (error) {
              console.log(error);
            }
          }
        });
    },

    // 보류 처리
    goUpdateRejectStatus() {
      const oprtrId = this.popup.oprtrId;
      const returnReasonCn = this.rejectPopup.returnReasonCn.value;

      if (!oprtrId) {
        this.$dialogs.alert('아이디가 없습니다. 목록에서 선택해 주세요.');
        return;
      }
      if (!returnReasonCn) {
        this.$dialogs.alert('보류 사유를 입력해 주세요.');
        return;
      }

      this.$dialogs
        .confirm(`<p>보류처리 하시겠습니까?</p>`)
        .then(async ({ ok }) => {
          if (ok) {
            try {
              const params = {
                confmSttusCode: 2,
                oprtrId: oprtrId,
                returnReasonCn: returnReasonCn,
              };

              await processApproveStatus(params).then(({ data }) => {
                if (data.valid) {
                  this.$dialogs.alert('처리되었습니다.').then(() => {
                    this.closePopup('all');
                    // 판매자 입점 목록 갱신
                    this.initSearch();
                  });
                } else {
                  this.$dialogs.alert('보류처리 과정에서 에러가 발생했습니다.');
                }
              });
            } catch (error) {
              console.log(error);
            }
          }
        });
    },
  },
};
</script>

<style scoped>
.upper-ctgry span {
  margin-left: 20px;
  font-size: 14px;
  font-weight: bold;
}
.ctgryPanel div > ul > li {
  line-height: 32px;
  margin-left: 40px;
  font-size: 12px;
  font-weight: bold;
}

.approve-top-preview {
  height: 130px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  display: flex;
  margin-bottom: 20px;
}
.approve-top-preview div {
  padding: 0px 20px;
  margin: 10px 0px;
}
.approve-top-preview div:nth-child(1) {
  width: 25%;
}
.approve-top-preview div:nth-child(2) {
  width: 17%;
}
.approve-top-preview div:nth-child(3) {
  width: 17%;
}
.approve-top-preview div:nth-child(4) {
  width: 17%;
  border-right: 1px solid #ddd;
}
.approve-top-preview div:nth-child(5) {
  width: 24%;
}

.approve-top-info {
  text-align: center;
  width: 100%;
}

.approve-status-div {
  display: table;
  width: 100%;
  height: 100px;
  border-right: 1px solid #ddd;
}
.approve-status-div div:nth-child(1) {
  width: 20%;
}
.approve-status-div div:nth-child(2) {
  width: 60%;
}
.approve-status-div div:nth-child(3) {
  width: 20%;
}

.approve-status-div .title {
  font-size: 13px;
  font-weight: 700;
  vertical-align: middle;
}
.approve-top-info p:nth-child(1) {
  line-height: 50px;
  font-size: 15px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.approve-top-info p:nth-child(2) {
  font-size: 30px;
  font-weight: bold;
}
.approve-top-info p:nth-child(2) > small {
  font-size: 20px;
  margin-left: 4px;
  opacity: 0.4;
}

.approve-top-info p:nth-child(3) {
  font-size: 30px;
  font-weight: bold;
}
.approve-top-info p:nth-child(3) > small {
  font-size: 20px;
  margin-left: 4px;
  opacity: 0.4;
}

.approve-top-info-detail p:nth-child(1) {
  line-height: 40px;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  margin-bottom: 5px;
  text-align: left;
  width: 100%;
}
.approve-top-info-detail p > input {
  float: right;
  margin-top: 10px;
}
.approve-top-info-detail p {
  font-size: 13px;
  line-height: 25px;
  text-align: right;
}
.approve-top-info-detail p > label {
  font-weight: 800;
  float: left;
}
.approve-top-info-detail p > span {
  font-weight: 800;
}
.approve-top-info-detail p > span > small {
  margin-left: 4px;
  opacity: 0.6;
}

.approve-status-item {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Noto Sans KR', sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.approve-status-item button {
  width: 30px;
  height: 30px;
}
.pg-prev {
  width: 20px;
  height: 20px;
  margin: 5px 0 0 5px;
  background: url('../../assets/images/pg_prev.png') no-repeat;
}
.pg-next {
  width: 20px;
  height: 20px;
  margin: 5px 0 0 7px;
  background: url('../../assets/images/pg_next.png') no-repeat;
}

.file-name-div {
  display: inline-block;
  width: 250px;
  overflow: auto;
}

.passive {
  border-radius: 6%;
  border-style: outset;
  border-width: 1px;
  background-color: #efefef;
}

.active {
  border-radius: 6%;
  border-style: outset;
  border-width: 1px;
  background-color: rgba(221, 221, 221, 0.952);
}
</style>
