var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.popShow && (_vm.openPopType == 'imgDetail' || _vm.openPopType == 'imgInsert'))?_c('div',{staticClass:"pop-drag",attrs:{"title":""}},[_c('div',{staticClass:"popup-mask"}),_c('div',{staticClass:"pop-content pop-ani pop-drag",staticStyle:{"width":"500px"}},[_c('div',{staticClass:"pop-head"},[(_vm.openPopType == 'imgDetail')?_c('h2',[_vm._v("배너 이미지")]):(_vm.openPopType == 'imgInsert')?_c('h2',[_vm._v("배너 이미지 등록")]):_vm._e(),_c('button',{staticClass:"btn-x",on:{"click":function($event){return _vm.closePopup()}}})]),_c('div',{staticClass:"pop-body"},[_c('validation-observer',{ref:"observer"},[_c('form',{attrs:{"id":"updateBannerImageForm","enctype":"multipart/form-data"}},[_c('table',{staticClass:"table-style-default table-pop"},[_c('colgroup',[_c('col',{attrs:{"width":"120px"}}),_c('col',{attrs:{"width":"300px"}})]),_c('tbody',[_c('tr',[_c('th',[_vm._v(" 배너 이미지 이름 "),(_vm.openPopType == 'imgInsert')?_c('i',{staticClass:"star"},[_vm._v("*")]):_vm._e()]),_c('td',[_c('validate-form',{attrs:{"label":"배너 이미지 이름","rules":{ required: true, max: 20 },"inputClass":"input-24 w-100","maxlength":"20","dataType":"LITERAL"},model:{value:(_vm.updateItem.bannerImageNm),callback:function ($$v) {_vm.$set(_vm.updateItem, "bannerImageNm", $$v)},expression:"updateItem.bannerImageNm"}})],1)]),_c('tr',[_c('th',[_vm._v(" 링크 "),(_vm.openPopType == 'imgInsert')?_c('i',{staticClass:"star"},[_vm._v("*")]):_vm._e()]),_c('td',[_c('validate-form',{attrs:{"label":"링크","rules":{
                      max: 200,
                      regex: /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                      required: true,
                    },"errorData":{
                      regex: 'http(s):// 형식으로 입력해 주세요.',
                    },"maxlength":"200","inputClass":"input-24 w-100","placeholder":"http://~ 형식으로 작성","dataType":"URL"},model:{value:(_vm.updateItem.linkUrl),callback:function ($$v) {_vm.$set(_vm.updateItem, "linkUrl", $$v)},expression:"updateItem.linkUrl"}})],1)]),_c('tr',[_c('th',[_vm._v(" 배너 이미지 "),(_vm.openPopType == 'imgInsert')?_c('i',{staticClass:"star"},[_vm._v("*")]):_vm._e()]),_c('td',{attrs:{"id":"u_bannerImageFile"}},[(_vm.openPopType == 'imgDetail')?_c('img',{staticStyle:{"width":"300px","cursor":"pointer"},attrs:{"src":`${_vm.imageSrc}`,"title":"클릭하시면 원본크기로 보실 수 있습니다."},on:{"click":function($event){return _vm.imgPopUp(_vm.imageSrc)}}}):_vm._e(),_c('div',{staticStyle:{"padding-top":"8px"}},[_c('span',{staticStyle:{"padding-right":"4px"}},[_c('validate-form',{attrs:{"label":"파일명","name":"fileNm","rules":{
                          regex: /([^\s]+(?=\.(bmp|jpeg|jpg|png|gif))\.\2)/,
                          required: _vm.openPopType == 'imgInsert' ? true : false,
                        },"errorData":{
                          ..._vm.errorData,
                          regex: '파일 확장자는 이미지 파일만 가능합니다.',
                        },"inputClass":"input-24","inputStyle":"width: 234px; border: 1px solid #ccc; padding: 4px 4px 4px 6px;","readonly":true},model:{value:(_vm.fileNm),callback:function ($$v) {_vm.fileNm=$$v},expression:"fileNm"}})],1),_c('span',[_c('input',{ref:"file",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.changeFile}}),_c('input',{staticClass:"bt-line24",attrs:{"type":"button","value":_vm.openPopType == 'imgDetail' ? '변경' : '업로드'},on:{"click":function($event){return _vm.$refs.file.click()}}})])])])])])])]),_c('p',{staticClass:"pop-btn-group"},[_c('input',{staticClass:"bt-line30 cr-orange",attrs:{"id":"updateBannerImageBtn","type":"button","value":_vm.openPopType == 'imgDetail' ? '수정' : '등록'},on:{"click":function($event){_vm.openPopType == 'imgDetail' ? _vm.updateSubmit() : _vm.insertSubmit()}}}),_c('input',{staticClass:"bt-line30",attrs:{"type":"button","value":"닫기"},on:{"click":function($event){return _vm.closePopup()}}})])])],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }