<template>
  <!-- 배너 이미지 보기 팝업 -->
  <div
    title=""
    class="pop-drag"
    v-if="popShow && (openPopType == 'imgDetail' || openPopType == 'imgInsert')"
  >
    <div class="popup-mask"></div>
    <div class="pop-content pop-ani pop-drag" style="width:500px">
      <div class="pop-head">
        <h2 v-if="openPopType == 'imgDetail'">배너 이미지</h2>
        <h2 v-else-if="openPopType == 'imgInsert'">배너 이미지 등록</h2>
        <button class="btn-x" @click="closePopup()"></button>
      </div>
      <div class="pop-body">
        <validation-observer ref="observer">
          <form id="updateBannerImageForm" enctype="multipart/form-data">
            <table class="table-style-default table-pop">
              <colgroup>
                <col width="120px" />
                <col width="300px" />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    배너 이미지 이름
                    <i v-if="openPopType == 'imgInsert'" class="star">*</i>
                  </th>
                  <td>
                    <validate-form
                      label="배너 이미지 이름"
                      :rules="{ required: true, max: 20 }"
                      inputClass="input-24 w-100"
                      maxlength="20"
                      dataType="LITERAL"
                      v-model="updateItem.bannerImageNm"
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    링크
                    <i v-if="openPopType == 'imgInsert'" class="star">*</i>
                  </th>
                  <td>
                    <validate-form
                      label="링크"
                      v-model="updateItem.linkUrl"
                      :rules="{
                        max: 200,
                        regex: /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                        required: true,
                      }"
                      :errorData="{
                        regex: 'http(s):// 형식으로 입력해 주세요.',
                      }"
                      maxlength="200"
                      inputClass="input-24 w-100"
                      placeholder="http://~ 형식으로 작성"
                      dataType="URL"
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    배너 이미지
                    <i v-if="openPopType == 'imgInsert'" class="star">*</i>
                  </th>
                  <td id="u_bannerImageFile">
                    <img
                      v-if="openPopType == 'imgDetail'"
                      :src="`${imageSrc}`"
                      style="width: 300px; cursor:pointer;"
                      title="클릭하시면 원본크기로 보실 수 있습니다."
                      @click="imgPopUp(imageSrc)"
                    />
                    <div style="padding-top: 8px;">
                      <span style="padding-right: 4px;">
                        <validate-form
                          label="파일명"
                          v-model="fileNm"
                          name="fileNm"
                          :rules="{
                            regex: /([^\s]+(?=\.(bmp|jpeg|jpg|png|gif))\.\2)/,
                            required: openPopType == 'imgInsert' ? true : false,
                          }"
                          :errorData="{
                            ...errorData,
                            regex: '파일 확장자는 이미지 파일만 가능합니다.',
                          }"
                          inputClass="input-24"
                          inputStyle="width: 234px; border: 1px solid #ccc; padding: 4px 4px 4px 6px;"
                          :readonly="true"
                        />
                      </span>
                      <span>
                        <input
                          type="file"
                          ref="file"
                          style="display:none"
                          accept="image/*"
                          @change="changeFile"
                        />
                        <input
                          type="button"
                          class="bt-line24"
                          :value="
                            openPopType == 'imgDetail' ? '변경' : '업로드'
                          "
                          @click="$refs.file.click()"
                        />
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <p class="pop-btn-group">
            <input
              id="updateBannerImageBtn"
              type="button"
              :value="openPopType == 'imgDetail' ? '수정' : '등록'"
              class="bt-line30 cr-orange"
              @click="
                openPopType == 'imgDetail' ? updateSubmit() : insertSubmit()
              "
            />
            <input
              type="button"
              value="닫기"
              class="bt-line30"
              @click="closePopup()"
            />
          </p>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import CommonMixins from '@/mixins/CommonMixins';
import store from '@/store/index';
import ValidateForm from '@/components/ValidateForm.vue';
import {
  detailBannerImg,
  updateBannerImg,
  insertBannerImg,
} from '@/api/marketApi';

export default {
  mixins: [CommonMixins],
  props: {
    openPopType: {
      type: String,
      required: true,
    },
    popShow: {
      type: Boolean,
      required: true,
    },
    clickedBannerImg: {
      type: Object,
      required: false,
    },
    clickedBannerSn: {
      type: String,
      required: true,
    },
  },
  components: { ValidateForm },
  data: () => ({
    imageSrc: '',
    updateItem: {
      bannerImageNm: '',
      linkUrl: '',
    },
    fileNm: '',
    file: {},
    errorData: {},
  }),
  methods: {
    closePopup() {
      this.clearInput();
      this.$emit('close', false);
    },
    setUpdateItem() {
      if (this.openPopType == 'imgDetail') {
        this.getBannerImg();
      }
    },
    resetUpdateItem() {
      Object.keys(this.resetUpdateItem).map(
        k => (this.resetUpdateItem[k] = ''),
      );
    },
    async getBannerImg() {
      await detailBannerImg({
        bannerImageSn: this.clickedBannerImg.bannerImageSn,
      }).then(({ data }) => {
        this.updateItem.bannerImageNm = data.bannerImageNm;
        this.updateItem.linkUrl = data.linkUrl;
        this.imageSrc = `${process.env.VUE_APP_URL_MAS_API}/market/bannerView/${this.clickedBannerImg.bannerImageSn}?token=${store.state.auth.token}`;
      });
    },
    //이미지 클릭 시 원본 보여주기
    imgPopUp(imageUrl) {
      window.open(imageUrl);
    },
    changeFile() {
      this.file = this.$refs.file.files[0];
      this.fileNm = this.$refs.file.files[0].name;
    },
    async updateSubmit() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        this.$dialogs.alert('입력 내용을 확인해 주세요.');
        return;
      } else {
        const params = {
          bannerImageNm: this.updateItem.bannerImageNm,
          linkUrl: this.updateItem.linkUrl,
          bannerImageFile: this.file,
        };
        await updateBannerImg(this.clickedBannerImg.bannerImageSn, params).then(
          ({ data }) => {
            const { valid } = data;
            if (!valid) {
              this.$dialogs.alert(
                '배너 이미지를 수정하는 과정에서 에러가 발생했습니다.',
              );
              return;
            }
            this.$dialogs.alert('수정 되었습니다.').then(() => {
              this.closePopup();
              const reqBody = {
                bannerSn: this.clickedBannerSn,
              };
              this.$emit('getBannerList', reqBody);
            });
          },
        );
      }
    },
    async insertSubmit() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        this.$dialogs.alert('입력 내용을 확인해 주세요.');
        return;
      } else {
        const params = {
          bannerSn: this.clickedBannerSn,
          bannerImageNm: this.updateItem.bannerImageNm,
          linkUrl: this.updateItem.linkUrl,
          bannerImageFile: this.file,
        };
        await insertBannerImg(params).then(({ data }) => {
          const { valid } = data;
          if (!valid) {
            this.$dialogs.alert(
              '배너 이미지를 등록하는 과정에서 에러가 발생했습니다.',
            );
            return;
          }
          this.$dialogs.alert('등록 되었습니다.').then(() => {
            this.closePopup();
            const reqBody = {
              bannerSn: this.clickedBannerSn,
            };
            this.$emit('getBannerList', reqBody);
          });
        });
      }
    },
    clearInput() {
      this.imageSrc = '';
      this.updateItem.bannerImageNm = '';
      this.updateItem.linkUrl = '';
      this.fileNm = '';
      this.file = {};
    },
  },
  watch: {
    clickedBannerImg: {
      deep: true,
      handler() {
        if (!this.isEmptyObject(this.clickedBannerImg)) {
          this.setUpdateItem();
        } else {
          this.resetUpdateItem();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
