<template>
  <!------------------------ 배너 그룹 등록 팝업 ------------------------>
  <div
    class="pop-drag"
    v-if="popShow && (openPopType == 'insert' || openPopType == 'update')"
  >
    <div class="popup-mask"></div>
    <div class="pop-content pop-ani pop-drag" style="width:500px">
      <div class="pop-head">
        <h2>배너 {{ submitType }}</h2>
        <button class="btn-x" @click="closePopup()"></button>
      </div>
      <div class="pop-body">
        <validation-observer ref="observer">
          <form id="insertBannerForm" enctype="multipart/form-data">
            <table class="table-style-default table-pop">
              <colgroup>
                <col width="120px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <th>사용 여부<i class="star">*</i></th>
                  <td>
                    <select
                      type="text"
                      name="useAt"
                      class="input-24 w-100"
                      v-model="updateItem.useAt"
                    >
                      <option value="Y">사용</option>
                      <option value="N">보류</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>배너 구분<i class="star">*</i></th>
                  <td>
                    <select
                      type="text"
                      name="bannerTypeCode"
                      class="input-24 w-100"
                      v-model="updateItem.bannerTypeCode"
                    >
                      <option
                        v-for="(item, index) in bnTypeList"
                        :key="index"
                        :value="item.codeId"
                      >
                        {{ item.codeName }}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>배너 이름<i class="star">*</i></th>
                  <td>
                    <validate-form
                      label="배너 이름"
                      :rules="{ required: true, max: 20 }"
                      inputClass="input-24 w-100"
                      maxlength="20"
                      dataType="LITERAL"
                      v-model="updateItem.bannerNm"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <p class="pop-btn-group">
            <input
              type="button"
              :value="submitType == '등록' ? submitType : '수정'"
              class="bt-line30 cr-orange"
              @click="submitType == '등록' ? insertSubmit() : updateSubmit()"
            />
            <input
              type="button"
              value="닫기"
              class="bt-line30"
              @click="closePopup()"
            />
          </p>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import ValidateForm from '@/components/ValidateForm.vue';
import { insertBanner, updateBanner } from '@/api/marketApi';

export default {
  props: {
    openPopType: {
      type: String,
      required: true,
    },
    popShow: {
      type: Boolean,
      required: true,
    },
    bnTypeList: {
      type: Array,
      required: true,
    },
    submitType: {
      type: String,
      required: true,
    },
    selctedItem: {
      type: Object,
      required: true,
    },
  },
  components: {
    ValidateForm,
  },
  data: () => ({
    updateItem: {
      bannerNm: '',
      bannerTypeCode: 'E',
      useAt: 'Y',
      bannerSn: '',
      errorData: {},
    },
    emitItem: {
      bannerNm: '',
      bannerTypeCode: 'E',
      useAt: 'Y',
      bannerSn: '',
      errorData: {},
    },
  }),
  methods: {
    closePopup() {
      this.clearSetting();
      this.$emit('close', false);
    },
    clearSetting() {
      this.updateItem.bannerNm = '';
      this.updateItem.bannerTypeCode = 'E';
      this.updateItem.useAt = 'Y';
      this.updateItem.bannerSn = '';
      this.updateItem.creatDt = null;
      this.updateItem.bannerTypeNm = '';
      this.updateItem.bannerImageList = [];
    },
    async insertSubmit() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        this.$dialogs.alert('입력 내용을 확인해 주세요.');
        return;
      } else {
        const params = {
          bannerNm: this.updateItem.bannerNm,
          bannerTypeCode: this.updateItem.bannerTypeCode,
          useAt: this.updateItem.useAt.trim(),
        };
        await insertBanner(params).then(({ data }) => {
          const { valid } = data;
          if (!valid) {
            this.$dialogs.alert(
              '배너를 등록하는 과정에서 에러가 발생했습니다.',
            );
            return;
          }
          this.$dialogs.alert('등록 되었습니다.').then(() => {
            const reqbody = {
              page: 1,
              rows: this.nowPerPage,
              sidx: null,
              sord: 'asc',
              bannerNm: '',
              bannerTypeCode: '',
            };
            this.closePopup();
            this.$emit('getMarketBanner', reqbody);
            this.emitItem.bannerNm = '';
            this.emitItem.bannerTypeCode = '';
            this.emitItem.useAt = '';
            this.emitItem.bannerSn = '';
            this.emitItem.creatDt = null;
            this.emitItem.bannerTypeNm = '';
            this.emitItem.bannerImageList = [];
            this.$emit('updateItem', this.emitItem);
          });
        });
      }
    },
    async updateSubmit() {
      const params = {
        bannerSn: String(this.updateItem.bannerSn),
        bannerNm: this.updateItem.bannerNm,
        bannerTypeCode: this.updateItem.bannerTypeCode,
        useAt: this.updateItem.useAt,
      };
      await updateBanner(params).then(({ data }) => {
        const { valid } = data;
        if (!valid) {
          this.$dialogs.alert('배너를 수정하는 과정에서 에러가 발생했습니다.');
          return;
        }
        this.$dialogs.alert('수정되었습니다.').then(() => {
          const reqbody = {
            page: 1,
            rows: this.nowPerPage,
            sidx: null,
            sord: 'asc',
            bannerNm: '',
            bannerTypeCode: '',
          };
          this.$emit('getMarketBanner', reqbody);
          this.$emit('updateItem', this.updateItem);
          this.closePopup();
        });
      });
    },
    setUpdateItem() {
      if (this.submitType == '등록') {
        this.clearSetting();
        return;
      }
      this.updateItem.bannerNm = this.selctedItem.bannerNm;
      this.updateItem.bannerTypeCode = this.selctedItem.bannerTypeCode;
      this.updateItem.useAt = this.selctedItem.useAt;
      this.updateItem.bannerSn = this.selctedItem.bannerSn;
      this.updateItem.bannerTypeNm = this.selctedItem.bannerTypeNm;
      this.updateItem.bannerImageList = this.selctedItem.bannerImageList;
      this.updateItem.creatDt = this.selctedItem.creatDt;
    },
  },
  watch: {
    selctedItem: {
      deep: true,
      handler() {
        if (!(this.selctedItem.bannerSn == '')) {
          this.setUpdateItem();
        } else {
          this.clearSetting();
        }
      },
    },
    popShow() {
      this.setUpdateItem();
    },
  },
};
</script>
<style lang="scss" scoped></style>
